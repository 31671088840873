import {Injectable} from '@angular/core';
import * as _ from 'lodash';

import {RequestService} from './request.service';
import {AuthData} from '../models/auth-data';
import {EmailData} from '../models/email-data';
import {ResetPassword} from '../models/reset-password';

@Injectable()
export class ApiService {

  authToken = {
    post: (auth: AuthData) => this.request
      .post()
      .url('partner/auth/token')
      .payload(auth),
  };
  register = {
    post: (auth: AuthData) => this.request
      .post()
      .url('partner/auth/register')
      .payload(auth),
  };
  me_admin = {
    get: () => this.request
      .get()
      .url('admins/me')
      .auth(),
  };
  request_password = {
    post: (data: EmailData) => this.request
      .post()
      .url('partner/pwresetrq')
      .payload(data),
  };
  reset_password = {
    post: (data: ResetPassword) => this.request
      .post()
      .url('partner/pwreset')
      .payload(data),
  };
  drivers = {
    get: () => this.request
      .get()
      .url('partner/workers')
      .auth(),
    getById: (id) => this.request
      .get()
      .url(`frontend/workers/${id}`)
      .auth(),
  };
  invite = {
    get: () => this.request
      .get()
      .url('partner/get-inviter')
      .auth(),
    post: (obj) => this.request
      .post()
      .url('partner/mail-invite')
      .payload(obj)
      .auth(),
  };
  ridetype = {
    get: () => this.request
      .get()
      .url('frontend/type/ride')
      .auth(),
  };
  workerStatus = {
    get: (req) => this.request
      .post()
      .url('front/booking/getNearbyWorkers')
      .payload(req)
      .auth(),
  };
  car = {
    get: () => this.request
      .get()
      .url('mobile/car/filter'),
  };
  vehicle = {
    get: () => this.request
      .get()
      .url('partner/vehicles')
      .auth(),
    getWorkerVehicles: (worker_id) => this.request
      .get()
      .url(`partner/worker/${worker_id}/vehicles`)
      .auth(),
    post: (vehicle: any) => this.request
      .post()
      .url('partner/vehicle/add')
      .file(vehicle)
      .auth(),
  };
  vehicle_invite = {
    get: () => this.request
      .get()
      .url('partner/vehicle-inviter')
      .auth(),
    post: (obj) => this.request
      .post()
      .url('partner/vehicle-invite')
      .payload(obj)
      .auth(),
  };
  partner_bank = {
    get: () => this.request
      .get()
      .url('partner/banks')
      .auth(),
    getBalance: () => this.request
      .get()
      .url('partner/bank_balance')
      .auth(),
    post: (obj) => this.request
      .post()
      .url('partner/create-bank')
      .payload(obj)
      .auth(),
    set_default: (obj) => this.request
      .put()
      .url('partner/default-bank')
      .payload(obj)
      .auth(),
    deleteBank: (obj) => this.request
      .post()
      .url('partner/delete-bank')
      .payload(obj)
      .auth(),
    withdraw: (obj) => this.request
      .post()
      .url('partner/withdraw')
      .payload(obj)
      .auth(),
  };
  booking = {
    get: () => this.request
      .get()
      .url('partner/book/ride')
      .auth(),
    getById: (id) => this.request
      .get()
      .url(`partner/book/${id}`)
      .auth(),
    getSummary: () => this.request
      .get()
      .url('frontend/book/ride/summary')
      .auth(),
    getDailySummary: () => this.request
      .get()
      .url('frontend/book/ride/daily-summary')
      .auth(),
  };
  map = {
    get: (req) => this.request
      .post()
      .url('partner/booking/getNearbyWorkers')
      .payload(req)
      .auth(),
  };
  constructor(private request: RequestService) {
  }

}
