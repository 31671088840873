import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { MeService } from './me.service';

@Injectable()
export class ConfService {

  ROLE_ADMIN = 0;
  ROLE_PARTNER = 1;
  ROLE_CONCIERGE = 2;
  ROLE_WORKER = 3;

  PARTNER_CAR = 1;
  PARTNER_BOAT = 2;
  PARTNER_PHYSICAL_THERAPIST = 3;
  PARTNER_MASSAGE = 4;

  INVITER_TYPE_ADMIN = 0;
  INVITER_TYPE_PATNER = 1;
  INVITER_TYPE_CONCIERGE = 2;
  INVITER_TYPE_WORKER = 3;

  RIDE_TYPE_SUV = 1;
  RIDE_TYPE_LUX = 2;
  RIDE_TYPE_EXOTICS = 3;
  RIDE_TYPE_SPRINTER = 4;
  RIDE_TYPE_ECO = 5;

  VEHICLE_IS_PARTNER = 1;
  VEHICLE_IS_WORKER = 0;


  smallPage: number = 15;
  middlePage: number = 35;
  largePage: number = 50;

  admin_id: number = null;

  constructor(private me: MeService) {
    if (this.me.admin) {
      this.admin_id = this.me.admin.id;
    }
  }
}
