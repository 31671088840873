import { Injectable } from '@angular/core';

import { MeService } from './me.service';

import { Admin } from '../models/admin';
import {ApiService} from '../@api/api.service';
import {AuthData} from '../models/auth-data';

@Injectable()
export class AuthService {

  constructor(private api: ApiService, private me: MeService ) { }

  rememberToken(token) {
    localStorage.setItem('wgl.partner_token', token);
  }

  getToken() {
    return localStorage.getItem('wgl.partner_token');
  }

  canAutoLogin(): boolean {
    return this.getToken() !== null;
  }

  isAuthenticated(): boolean {
    return this.me.token !== null;
  }

  autoLogin(): Promise<Admin> {
    const token = this.getToken();
    if (token === null) {
      return Promise.reject({'error': 'New user'});
    }
    this.me.setToken(token);
      return this.api.me_admin.get().promise()
        .then((resp) => {
          this.me.setAdmin(resp[0]);
          this.me.setRole(resp[0].type);
          return resp[0];
        });
  }

  login(auth: AuthData): Promise<Admin> {
    return this.api.authToken.post(auth).promise().then(token => {
      this.me.setToken(token.token);
      this.rememberToken(token.token);
      }).then(() => this.api.me_admin.get().promise()).then((resp) => {
        if (resp[0].status === 0) {
          this.me.forget();
          localStorage.removeItem('wgl.partner_token');
          return null;
        } else {
          this.me.setAdmin(resp[0]);
          this.me.setRole(resp[0].type);
          return resp[0];
        }
      });
  }

  logout(): void {
    this.me.forget();
    localStorage.removeItem('wgl.partner_token');
  }

}
